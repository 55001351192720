<script lang="ts" setup>
import { usePageStore } from '@/stores'
import { extractVideoID } from '@/utils/helper'
const pageStore = usePageStore()
const { pcBanner, mobileBanner } = pageStore
let pcBannerVideoID: string | null
let mobileBannerVideoID: string | null
if (pcBanner.length > 0 && pcBanner[0]) {
  pcBannerVideoID = extractVideoID(pcBanner[0].media)
}
if (pcBanner.length > 0 && mobileBanner[0]) {
  mobileBannerVideoID = extractVideoID(mobileBanner[0].media)
}
</script>

<template>
  <div class="video-box" v-if="pcBanner.length > 0">
    <!-- <video muted autoplay loop ref="refVideo">
      <source src="@/assets/istockphoto-1006346824-640_adpp_is.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->
    <div class="video-background">
      <iframe width="100%" height="100%"
        :src="`https://www.youtube.com/embed/${pcBannerVideoID}?playlist=${pcBannerVideoID}&autoplay=1&controls=0&disablekb=1&loop=1&modestbranding=1&playsinline=1&color=white&mute=1&rel=0`"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
        allowfullscreen v-if="pcBanner.length > 0"></iframe>
    </div>
    <WelcomeAnimation />
  </div>
</template>

<style lang="scss" scoped>
.video-box {
  position: relative;
  height: calc(100vh - 88px);
  background-color: #000;
  pointer-events: none;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }
}

* {
  box-sizing: border-box;
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    // width: 177.78vh; 
    // 遮標題
    width: 300.78vh;
  }
}
</style>
