<script lang="ts" setup>
const loadingClass: Ref<string> = ref('')

onMounted(() => {
  setTimeout(() => {
    loadingClass.value = 'text-in'
  }, 250)
  setTimeout(() => {
    loadingClass.value = 'text-in text-in-center'
  }, 1200)
  setTimeout(() => {
    loadingClass.value = 'text-in text-in-center text-out '
  }, 2000)
  setTimeout(() => {
    loadingClass.value = 'text-in text-in-center text-out logo-in'
  }, 2500)
  setTimeout(() => {
    loadingClass.value = 'text-in text-in-center text-out logo-in success'
  }, 3000)
})
</script>

<template>
  <div class="loading" :class="loadingClass">
    <div class="loading__text">
      <div class="loading__text-left">讓生活</div>
      <div class="loading__text-center">
        <span class="loading__text-center__text">大於</span>
      </div>
      <div class="loading__text-right">想像</div>
    </div>
    <div class="loading__logo">
      <svg-icon name="logo" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  position: absolute;
  z-index: 900;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white-1;
  transition: all 0.7s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: toRem(-88);
    width: 100%;
    height: toRem(88);
    background-color: $color-white-1;
    transition: all 0.7s ease-in-out;
  }

  &__text {
    position: absolute;
    left: 50%;
    top: calc(50% + toRem(44));
    transform: translate(-60%, -50%);
    opacity: 0;
    display: flex;
    justify-content: center;
    font-size: toRem(72);
    font-weight: 700;
    transition: all 0.7s cubic-bezier(0.87, 0, 0.13, 1);

    @include max-media(767) {
      width: 100vw;
      font-size: 7vw;
    }

    &-center {
      position: relative;
      margin-left: toRem(24);
      margin-right: toRem(24);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1vw;
        background-color: $color-brand-1;
        transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
      }

      &__text {
        opacity: 0;
        color: $color-brand-1;
        transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
      }
    }

    &-right {
      opacity: 0;
      transform: translateX(-120%);
      transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
    }
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: toRem(50);
    z-index: 10;
    width: 100%;
    padding-left: toRem(20);
    padding-right: toRem(20);
    max-width: toRem(690);
    color: $color-white-1;
    transition: all 0.7s ease-in-out;
  }

  &.text-in {
    .loading {
      &__text {
        opacity: 1;
        transform: translate(-50%, -50%);

        &-center {
          &::after {
            width: 100%;
            transition-delay: 0.3s;
          }
        }

        &-right {
          opacity: 1;
          transform: translateX(0%);
          transition-delay: 0.3s;
        }
      }
    }
  }

  &.text-in-center {
    .loading {
      &__text {
        &-center {
          &::after {
            left: 100%;
            width: 0;
            opacity: 1;
          }

          &__text {
            opacity: 1;
          }
        }
      }
    }
  }

  &.text-out {
    .loading {
      &__text {
        opacity: 0;
      }
    }
  }

  &.logo-in {
    .loading {
      &__logo {
        color: $color-black-1;
      }
    }
  }

  &.success {
    background-color: transparent;
    pointer-events: none;

    &::after {
      opacity: 0;
    }

    .loading {
      &__logo {
        color: $color-white-1;
      }
    }
  }
}
</style>
